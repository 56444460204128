const SHINE_MEMBER_BLACK_IDS = [1327, 1420];
const SHINE_MEMBER_SILVER_IDS = [775, 1328, 1421];
const PRODUCT_VOUCHER_CATEGORY = 208;
const PRODUCT_TOPUP_CATEGORY = 155;
const BOOKING_HOT_TIME_EXTRA_FEE_SERVICE_ID = parseInt(
	process.env.REACT_APP_BOOKING_HOT_TIME_EXTRA_FEE_SERVICE_ID
);
const TOPUP_PRODUCT_CATEGORY = parseInt(process.env.REACT_APP_TOPUP_PRODUCT_CATEGORY);
const CAMPAIGN_TOPUP_PRODUCT_CATEGORY = parseInt(
	process.env.REACT_APP_CAMPAIGN_TOPUP_PRODUCT_CATEGORY
);
const GENZ_CAMPAIGN_ID = parseInt(process.env.REACT_APP_GENZ_CAMPAIGN_ID);
const REACT_APP_CAMP_ID_AUTO_TICK = parseInt(process.env.REACT_APP_CAMP_ID_AUTO_TICK);
export default {
	SHINE_MEMBER_BLACK_IDS,
	SHINE_MEMBER_SILVER_IDS,
	BOOKING_HOT_TIME_EXTRA_FEE_SERVICE_ID,
	TOPUP_PRODUCT_CATEGORY,
	CAMPAIGN_TOPUP_PRODUCT_CATEGORY,
	GENZ_CAMPAIGN_ID,
	PRODUCT_VOUCHER_CATEGORY,
	PRODUCT_TOPUP_CATEGORY,
	REACT_APP_CAMP_ID_AUTO_TICK
};
